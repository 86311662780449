import { StackedBarsSeries } from './stacked-bars-series';

export function createStackedBarSeries(chart, data, options = {}) {
  
  const stackedBarsSeries = new StackedBarsSeries();
  const series = chart.addCustomSeries(stackedBarsSeries, options);
  series.setData(data);
  console.log('data: ', data);

  return series;
}
