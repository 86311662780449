import { Box, Grid, IconButton } from '@mui/material';
import DateManager from '../DateManager';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchDailyChart } from '../../util/http';
import Loading from '../Loading';
import Error from '../Error';
import StandardChart from '../StandardChart';
import NoTicker from '../ticker/NoTicker';
import { Fullscreen } from '@mui/icons-material';
import ChartFullscreenDialog from '../ChartFullscreenDialog';
import DailyMeta from './DailyMeta';
import { createDistinctColors } from '../../util/Util';
import _ from 'lodash';
import PriceManager from './PriceManager';

export default function DailyTab({ selectedTicker, selectedDate, handleSetDate, price, handleSetPrice, charts }) {

    const [chartsStatus, setChartsStatus] = useState(Array(charts.length).fill({
        hovered: false,
        fullscreen: false,
    }));


    const queryDate = selectedDate === 'latest' ? DailyMeta.getInstance().getLatest() : selectedDate;
    const queryCharts = charts.map((chart) => chart.id);


    const { data: { data: chartData, metadata: chartMetadata } = { data: [], metadata: [] }, isPending, isError, error } = useQuery({
        queryKey: ['dailyChart', selectedTicker, queryDate, price, [...queryCharts]],
        queryFn: () => fetchDailyChart(selectedTicker, queryDate, queryCharts, price),
        enabled: selectedTicker !== null && selectedTicker !== undefined,
    });

    useEffect(() => {
        if (!price && chartMetadata)
            handleSetPrice(chartMetadata.price);
    }, [price, chartMetadata, handleSetPrice]);

    //console.log('price: ', price);

    const createOption = useCallback((chartInfo) => {
        if (!chartData || chartData.length === 0)
            return null;
        //console.log(chartInfo);
        //console.log(chartData);
        const id = chartInfo.id;
        const description = chartInfo.description;
        const data = chartData[id];
        const objectKeys = Object.keys(data);
        const maxLabels = 50 * (chartInfo.width === 'full' ? 2 : 1);
        //console.log(chartInfo);
        //console.log('chart id: , chartdata len = ', id, data[objectKeys[0]].length);

        const option = {
            title: {
                text: description,
                left: 'center',
                top: '5px',//4
            },
            grid: {
                left: '10px',//'4%',
                right: '10px', //'4%',
                bottom: '10px',//'10%',
                top: '40px',//25
                containLabel: true,
            },
            tooltip: {
                trigger: 'item',
                position: function (point, params, dom, rect, size) {
                    const xOffset = 10;
                    const chartWidth = size.viewSize[0];
                    const chartHeight = size.viewSize[1];
                    const viewportHeight = window.innerHeight;

                    let x = point[0];
                    let y = point[1];

                    if (x + xOffset + dom.offsetWidth > chartWidth)
                        x -= xOffset + dom.offsetWidth;
                    else
                        x += xOffset;

                    //console.log('mouse y: ', window.event?.clientY);

                    if (y - dom.offsetHeight / 2 >= 0 && y + dom.offsetHeight / 2 <= chartHeight)
                        y -= dom.offsetHeight / 2;
                    else if (window.event?.clientY + dom.offsetHeight > viewportHeight)
                        y -= window.event?.clientY - (dom.offsetHeight < viewportHeight ? viewportHeight - dom.offsetHeight : 0);
                    // else 
                    //     y -= dom.offsetHeight;
                    // else if (y - dom.offsetHeight >= 0)
                    //     y -= dom.offsetHeight;

                    return [x, y];
                },
                // formatter: function (params) {
                //     //console.log(params);
                //     let tooltipContent = '';
                //     if (Array.isArray(params)) {
                //         tooltipContent += `<div style="font-weight: bold">${params[0].name}</div>`;
                //         params.forEach(param => {
                //             if (param.value) {
                //                 tooltipContent += `<div>${param.marker} <span style="color:${param.color}">${param.seriesName}</span>: <span style="font-weight: bold">${param.value}</span></div>`;
                //             }
                //         });
                //     }
                //     else {
                //         tooltipContent += `<div style="font-weight: bold">${params.seriesName}</div>`;
                //         if (params.value) {
                //             tooltipContent += `<div>${params.marker} <span style="color:${params.color}">${params.name}</span>: <span style="font-weight: bold">${params.value}</span></div>`;
                //         }

                //     }
                //     return tooltipContent;
                // },
                formatter: function (params) {
                    //console.log(params);
                    let tooltipContent = '';
                    if (Array.isArray(params)) {
                        let positiveParams = [];
                        let negativeParams = [];
                        tooltipContent += `<div style="font-weight: bold">${params[0].name}</div>`;
                        params.forEach(param => {
                            if (param.value > 0) {
                                //tooltipContent += `<div>${param.marker} <span style="color:${param.color}">${param.seriesName}</span>: <span style="font-weight: bold">${param.value}</span></div>`;
                                positiveParams.push(param);
                            } else if (param.value < 0) {
                                negativeParams.push(param);
                            }
                            else {
                                if (param.seriesName.startsWith('P'))
                                    negativeParams.push(param);
                                else
                                    positiveParams.push(param);
                            }
                        });

                        tooltipContent += '<table>';
                        for (let i = 0; i < Math.max(positiveParams.length, negativeParams.length); ++i) {
                            tooltipContent += '<tr>';
                            if (negativeParams[i])
                                tooltipContent += `<td style="color:${negativeParams[i].color};">${negativeParams[i].marker} <span>${negativeParams[i].seriesName}</span>: <span style="font-weight: bold;">${negativeParams[i].value}</span></td>`;
                            else
                                tooltipContent += '<td></td>';

                            if (positiveParams[i])
                                tooltipContent += `<td style="color:${positiveParams[i].color};">${positiveParams[i].marker} <span>${positiveParams[i].seriesName}</span>: <span style="font-weight: bold;">${positiveParams[i].value}</span></td>`;
                            else
                                tooltipContent += '<td></td>';
                        }
                        tooltipContent += '</table>';

                    }
                    else {
                        tooltipContent += `<div style="font-weight: bold">${params.seriesName}</div>`;
                        if (params.value) {
                            tooltipContent += `<div>${params.marker} <span style="color:${params.color}">${params.name}</span>: <span style="font-weight: bold">${params.value}</span></div>`;
                        }

                    }
                    return tooltipContent;
                },

            },
            legend: {
                data: objectKeys.length < 10 ? objectKeys.slice(1) : [],
                icon: 'rectangle',
                top: '23px',//15
                left: 'center',
            },
            xAxis:
            {
                type: 'category',
                data: data[objectKeys[0]],
                boundaryGap: true,
                axisPointer: {
                    type: "shadow",
                    show: true,
                    //label: { show: true },
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    interval: Math.round(data[objectKeys[0]].length / maxLabels) - 1,
                    rotate: 45,
                    fontSize: 8,
                },
            },
            yAxis: [
                {
                    position: 'left',
                    //offset: 10,
                    type: 'value',
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        formatter: function (value) {
                            //console.log(value);
                            if (value && value % 1000000 === 0)
                                return (value / 1000000) + 'M';
                            else if (value && value && value % 1000 === 0)
                                return (value / 1000) + 'K';
                            else
                                return value;
                        },
                        fontSize: 8,
                    },
                },
            ],
            series: objectKeys.slice(1).map((key) => ({
                name: key,
                data: data[key],
                type: 'bar',
                stack: id,
                //large: true,  
            })),
        };

        const colors = createDistinctColors(option.series.length / 2);
        let index = 0;
        option.series.forEach(serie => {
            if (serie.name.startsWith('C_')) {
                //serie.color = '#' + Math.floor(Math.random() * 16777215).toString(16);
                serie.color = colors[index++];
                const name = 'P' + serie.name.slice(1);
                //console.log('serie '+serie.name+' => '+name);
                //console.log('before ', option.series.find((callSerie) => callSerie.name === name));
                option.series.find((callSerie) => callSerie.name === name).color = serie.color;                
            }

        });

        if (typeof option.xAxis.data[0] === 'number'){
            option.series[0].markLine = {
                data: [
                    {
                        name: 'Price', 
                        xAxis: _.sortedIndex(option.xAxis.data, chartMetadata.price),
                    }
                ],
                symbol: ['none', 'none'],
                label: {
                    show: false,
                    align: 'left',
                    offset: [5, 20],
                    fontFamily: 'Roboto',
                    fontSize: 8,
                    color: 'white',
                    formatter: () => `Price: ${chartMetadata.price}`                    
                },
                lineStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 1,
                        y2: 1,
                        colorStops: [
                            { offset: 0, color: '#00FF00' }, // Bright green start
                            { offset: 1, color: '#66FF66' }  // Slightly darker green end
                        ],
                    },
                    type: 'solid',
                    opacity: 0.5,
                },
                tooltip: {
                    show: false  // Disable tooltip for markLine
                }
            };
        }

        return option;
    }, [chartData, chartMetadata]);

    const memoisedOption = useMemo(() => charts.map((chart) => createOption(chart)), [createOption, charts]);
    const memoisedFullscreenOption = useMemo(() => charts.map((chart) => {
        const option = createOption(chart);
        if (!option)
            return null;
        //option.yAxis[0].offset += 20;
        if (chart.width === 'half') {
            option.xAxis.axisLabel.interval = Math.round(option.xAxis.axisLabel.interval / 3);
        }
        return option;
    }), [createOption, charts]);

    const handleFullscreenClosed = useCallback(() => {
        const index = chartsStatus.findIndex((chart) => chart.fullscreen);
        setChartsStatus((prevStatus) => [...prevStatus.slice(0, index), { ...prevStatus[index], fullscreen: false },
        ...prevStatus.slice(index + 1)]);
    }, [chartsStatus]);

    if (isPending)
        return <Loading />;

    // if (isError) {
    //     return <Error error={error} />;
    // }
    
    if (!selectedTicker)
        return <NoTicker />;


    return (
        <Grid container rowSpacing={0} columnSpacing={0} /*sx={{  '& > *': { border: '3px solid white' } }}*/>
            <Grid item xs={6} sx={{ pointerEvents: 'none', height: '6vh', marginTop: '-48px', marginBottom: '0px' }} />
            {process.env.REACT_APP_DAILY_PRICE_FEATURE === 'true' &&
                <Grid item xs={3} sx={{ height: '6vh', marginTop: '-48px', marginBottom: '0px' }} >
                    <PriceManager
                        price={chartMetadata.price}
                        handleSetPrice={handleSetPrice}
                    />
                </Grid>
            }
            <Grid item xs={3} sx={{ height: '6vh', marginTop: '-48px', marginBottom: '0px' }}>
                <DateManager
                    date={selectedDate}
                    prevDate={chartMetadata.previous}
                    nextDate={chartMetadata.next}
                    minDate='01-01-2007'
                    handleSetDate={handleSetDate}
                />
            </Grid>
            {isError &&
                <Grid item
                    xs={12}
                    style={{ height: '45vh', marginBottom: '20px' }}
                >
                    <Error error={error} />
                </Grid>            
            }
            {!isError && chartData && charts && charts.map((chart, index) => {
                return (
                    <Grid item
                        key={chart.id}
                        xs={chart.width === 'full' ? 12 : 6}
                        style={{ height: '33vh', marginBottom: '20px' }}
                    >
                        <Box
                            sx={{ height: '100%', padding: '0px !important' }}
                            position='relative'
                            border="1px solid #ccc"
                            borderRadius={4}
                            onMouseEnter={() => setChartsStatus((prevStatus) => [...prevStatus.slice(0, index), { ...prevStatus[index], hovered: true }, ...prevStatus.slice(index + 1)])}
                            onMouseLeave={() => setChartsStatus((prevStatus) => [...prevStatus.slice(0, index), { ...prevStatus[index], hovered: false }, ...prevStatus.slice(index + 1)])}
                        >
                            {chartsStatus[index].hovered && !chartsStatus[index].fullscreen &&
                                <Box position='absolute' top={0} right={0} zIndex={2000} >
                                    <IconButton onClick={() => setChartsStatus((prevStatus) => [...prevStatus.slice(0, index).map((status) => ({ ...status, fullscreen: false })), { ...prevStatus[index], fullscreen: true }, ...prevStatus.slice(index + 1).map((status) => ({ ...status, fullscreen: false }))])}>
                                        <Fullscreen />
                                    </IconButton>
                                </Box>
                            }
                            <StandardChart
                                option={memoisedOption[index]}
                            />
                        </Box>
                    </Grid>
                );
            })}
            {!chartData && (
                <Box border="1px solid #ccc" borderRadius={4} sx={{ minHeight: '100%', minWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', direction: 'column' }}>
                    <p>No data available for this date.</p>
                </Box>
            )}
            {!isError && chartsStatus.find((chart) => chart.fullscreen) !== undefined &&
                <ChartFullscreenDialog
                    open={chartsStatus.find((chart) => chart.fullscreen) !== undefined}
                    option={memoisedFullscreenOption[chartsStatus.findIndex((chart) => chart.fullscreen)]}
                    handleClose={handleFullscreenClosed}
                />
            }
        </Grid>
    );
}