import { Paper } from "@mui/material";
import EChartsReactCore from "echarts-for-react/lib/core";
import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts';
import { GridComponent, TitleComponent, TooltipComponent, DataZoomComponent, LegendComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { myTheme } from "./EChartTheme";
import { memo } from "react";

echarts.use([TitleComponent, TooltipComponent, GridComponent, BarChart, CanvasRenderer, DataZoomComponent, LegendComponent]);
echarts.registerTheme('BrightByte', myTheme);

const ApacheFullscreenChart = memo(({option, onChartReady}) => {

    return (
        <Paper sx={{height:'100%'}}>
            <EChartsReactCore
                echarts={echarts}
                style={{height: '100%'}}
                theme={myTheme}
                option={option}
                notMerge={true}
                lazyUpdate={true}
                onChartReady={(echartsInstance) => {
                    if (onChartReady)
                        onChartReady(echartsInstance, null);
                }}
            />
        </Paper>
    );
});

export default ApacheFullscreenChart;