Object.defineProperty(exports, "__esModule", { value: true });
exports.StackedBarsSeriesRenderer = void 0;
var columns_1 = require("../helpers/dimensions/columns");
var positions_1 = require("../helpers/dimensions/positions");
function cumulativeBuildUp(arr) {
    var sum = 0;
    return arr.map(function (value) {
        var newValue = sum + value;
        sum = newValue;
        return newValue;
    });
}
var StackedBarsSeriesRenderer = /** @class */ (function () {
    function StackedBarsSeriesRenderer() {
        this._data = null;
        this._options = null;
    }
    StackedBarsSeriesRenderer.prototype.draw = function (target, priceConverter) {
        var _this = this;
        target.useBitmapCoordinateSpace(function (scope) {
            return _this._drawImpl(scope, priceConverter);
        });
    };
    StackedBarsSeriesRenderer.prototype.update = function (data, options) {
        this._data = data;
        this._options = options;
    };
    StackedBarsSeriesRenderer.prototype._drawImpl = function (renderingScope, priceToCoordinate) {
        var _a;
        if (this._data === null ||
            this._data.bars.length === 0 ||
            this._data.visibleRange === null ||
            this._options === null) {
            return;
        }
        var options = this._options;
        var bars = this._data.bars.map(function (bar) {
            return {
                x: bar.x,
                ys: cumulativeBuildUp(bar.originalData.values).map(function (value) { var _a; return (_a = priceToCoordinate(value)) !== null && _a !== void 0 ? _a : 0; }),
            };
        });
        (0, columns_1.calculateColumnPositionsInPlace)(bars, this._data.barSpacing, renderingScope.horizontalPixelRatio, this._data.visibleRange.from, this._data.visibleRange.to);
        var zeroY = (_a = priceToCoordinate(0)) !== null && _a !== void 0 ? _a : 0;
        var _loop_1 = function (i) {
            var stack = bars[i];
            var column = stack.column;
            if (!column)
                return { value: void 0 };
            var previousY = zeroY;
            var width = Math.min(Math.max(renderingScope.horizontalPixelRatio, column.right - column.left), this_1._data.barSpacing * renderingScope.horizontalPixelRatio);
            stack.ys.forEach(function (y, index) {
                var color = options.colors[index % options.colors.length];
                var stackBoxPositions = (0, positions_1.positionsBox)(previousY, y, renderingScope.verticalPixelRatio);
                renderingScope.context.fillStyle = color;
                renderingScope.context.fillRect(column.left, stackBoxPositions.position, width, stackBoxPositions.length);
                previousY = y;
            });
        };
        var this_1 = this;
        for (var i = this._data.visibleRange.from; i < this._data.visibleRange.to; i++) {
            var state_1 = _loop_1(i);
            if (typeof state_1 === "object")
                return state_1.value;
        }
    };
    return StackedBarsSeriesRenderer;
}());
exports.StackedBarsSeriesRenderer = StackedBarsSeriesRenderer;
