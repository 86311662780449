import { LOCAL_STORAGE_USER_META_KEY } from "./Constants";

const server_base_url = "https://api.bbbeacon.com/api/v1";
//const server_base_url = "https://trademonkey.duckdns.org/api/v1";
const wp_base_url = "https://www.bbbeacon.com/wp-json/custom/v1";

// save system
async function wordpressMetaRequest(method, key, value)
{
    let payload = value ? {
        meta: {
            [key]: value,
        }
    } : null;

    let url = `${wp_base_url}/users/me/custom_meta`;

    //if (method === 'GET')
    //    url += "?key=" + encodeURIComponent(key);

    //console.log("wordpressMetaRequest", method, key, payload);  

    const response = await fetch(url, {
        method,
        headers: {
            'Content-Type': 'application/json',
            'X-WP-Nonce': window.WP_NONCE,
        },
        credentials: 'include',
        ...(payload !== null && { body: JSON.stringify(payload) }),
    });

    return response;
}

export async function getUserMeta()
{
    if (process.env.NODE_ENV === 'development')
        return null;

    const storedJson = localStorage.getItem(LOCAL_STORAGE_USER_META_KEY);
    if (storedJson){
        const storedData = JSON.parse(storedJson);

        return storedData;
    }    

    const response = await wordpressMetaRequest('GET', null, null);

    if (!response.ok)
    {
        const error = new Error('Error while trying to get user meta data');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }    

    const data = await response.json();

    localStorage.setItem(LOCAL_STORAGE_USER_META_KEY, JSON.stringify(data.meta));

    return data.meta;

}

export async function setUserMeta(key, value)
{
    if (process.env.NODE_ENV === 'development')    
        return;
    //console.log("set zoom: ", value);
    const storedJson = localStorage.getItem(LOCAL_STORAGE_USER_META_KEY);
    if (storedJson){
        const storedData = JSON.parse(storedJson);
        storedData[key] = value;
        localStorage.setItem(LOCAL_STORAGE_USER_META_KEY, JSON.stringify(storedData));
    }

    const response = await wordpressMetaRequest('POST', key, value);

    if (!response.ok)
    {
        const error = new Error('Error while trying to set user meta data');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const data = await response.json();

    //console.log("set user meta: " + data);
    return data;

}


// wordpress calls
 async function fetchToken()
{   
    //console.log('wp nonce ', window.WP_NONCE);
    //console.log('fetch token');


    const response = await fetch(`${wp_base_url}/token`, {
        headers: {
            'Content-Type': 'application/json',
            'X-WP-Nonce': window.WP_NONCE,
        },
        credentials: 'include',        
    });
   

    if (!response.ok)
    {
        const error = new Error('Error while trying to get wp nonce');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const tokenData = await response.json();

    return tokenData;
}

let tokenPromise = null;
async function getAuthToken()
{
    //mdeacu token
    if (process.env.NODE_ENV !== 'production')
        return 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InVzZXJuYW1lIjoic29yaW4udGl0YUBnbWFpbC5jb20ifSwibmJmIjoxNzM3ODI1Mjg3LCJpYXQiOjE3Mzc4MjUyODcsImV4cCI6MTczNzg0Njg4NywiaXNzIjoidHJhZGVtb25rZXkuZHVja2Rucy5vcmciLCJhdWQiOiJ0cmFkZW1vbmtleTpjaGFydHMifQ.bZSTFpEvxuRWowRDJqYLIR5bs5oc70eL-EHkO-4dZsRL4e5Lpu9xesuA0Naj1c-BIXlP0rtzPkYnudKMkzpnAdZbNnPgwi3ItQmx3nPEAeNhHtOq5LpWpu0tWcK2IbRrmm7yn_dh4ZmaWGAh5vc7PF2-vpLdhnDFYaUKrSLzRB_Nr0nk4cpL48ITBSMWPq6FQjQlE238UEK-D8uERn60ulNO_W-lFJWv9iK7WNq9w9ITANooGV5miZGh6dNzOwUoUA-yVSmScJ-frv_YuB7td42iVmdfcj12b_5kH7Khq2SlGaGtxj8l1fCcV-AEH-MlNhVlr8_dF-VbDFX03ctrqg';

    let token = localStorage.getItem('token');
    //console.log('loadedToken', token);

    if (!token) {
        if (!tokenPromise) {
            tokenPromise = fetchToken().then(fetchedTokenData => {
                if (fetchedTokenData.data && fetchedTokenData.data.token){
                    token = fetchedTokenData.data.token;
                    localStorage.setItem('token', token);
                    tokenPromise = null;                                                                                                                                                                                                            
                    return token;
                }
                else
                    return null;
            }).catch(err => {
                tokenPromise = null;
                throw err;
            });
        }
        token = await tokenPromise;
    }    

    return token;
}

// server calls
async function fetchServerRequest(url, body)
{
    const token =  await getAuthToken();

    const response = await fetch(`${server_base_url}/${url}`, {
        method: body ? 'POST' : 'GET',
        headers: {
            'Authorization' :  `Bearer ${token}` ,
            'Content-Type': 'application/json',
        },
        ...( body && {
            body: JSON.stringify(body)
        }),
    });

    return response;
}

async function checkTokenFetchServerRequest(url, body = null)
{
    let response = await fetchServerRequest(url, body);

    //console.log('response: ', response);
    if (response.status === 403){
        const responseBody = await response.text();
        //console.log('response body: ', responseBody);
        if (responseBody.includes("token is expired")) {
            //console.log('trying to remove...');
            localStorage.removeItem('token');
            response = fetchServerRequest(url);            
        }
    }

    return response;

}

export async function fetchHistoricChart(ticker, metrics, extra_params = '', startDate, endDate)
{   
    //console.log('startDate: ', startDate);
    //console.log('endDate: ', endDate);
    const response = await checkTokenFetchServerRequest(`historic?asset=${ticker}&format=json/list&metrics=${metrics.join(',')}` + 
        (startDate !== null ? `&start_date=${startDate}` : '') + 
        (endDate !== null ? `&end_date=${endDate}` : '') +
        (`&${extra_params}`));

    if (!response.ok)
    {
        const error = new Error('Error while trying to fetch historical data');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const historicData = await response.json();

    return historicData;
}

export async function fetchAssets()
{   
    const response = await checkTokenFetchServerRequest('charts/assets');

    if (!response.ok)
    {
        const error = new Error('Error while trying to fetch assets');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const historicData = await response.json();

    return historicData;
}

export async function fetchAssetStartDate(asset)
{   
    const response = await checkTokenFetchServerRequest(`asset/eod/start_date?asset=${asset}`);

    if (!response.ok)
    {
        const error = new Error('Error while trying to fetch assets');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const data = await response.json();

    return data;
}

export async function fetchAssetsDailyGains(assets)
{   
    //console.log('assets: ', assets);
    const response = await checkTokenFetchServerRequest('assets/price', {
        assets,
    });

    if (!response.ok)
    {
        const error = new Error('Error while trying to fetch assets');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const historicData = await response.json();

    return historicData;
}


export async function fetchHistoricMeta()
{   
    const response = await checkTokenFetchServerRequest('historic/info');
   
    if (!response.ok)
    {
        const error = new Error('Error while trying to fetch assets');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const metaData = await response.json();

    return metaData;
}

export async function fetchDailyMeta()
{   
    const response = await checkTokenFetchServerRequest('charts/info');
   
    if (!response.ok)
    {
        const error = new Error('Error while trying to fetch assets');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const metaData = await response.json();

    return metaData;
}

export async function fetchDailyChart(ticker, date, charts, price){

    const response = await checkTokenFetchServerRequest(`charts?asset=${ticker}&format=json/list` + 
        `&date=${date}` + 
        (price ? `&price=${price}`:'') +
        `&chart_type=${charts.join(',')}`);
    
        if (!response.ok)
    {
        const error = new Error('Error while trying to fetch daily data');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const dailyData = await response.json();

    return dailyData;
}

export async function fetchAreaOfInterestChart(ticker, interval, date = null, expiration_from = null, cutoff = null){

    const response = await checkTokenFetchServerRequest(`aoi?use_strikes=1&assets=${ticker}&format=json/list` + 
        (`&timeframe=${interval}`) + 
        (date ? `&date=${date}` : '') +        
        (expiration_from ? `&expiration_from=${expiration_from}` : '') +
        (cutoff ? `&cutoff=${cutoff}` : ''));
    
        if (!response.ok)
    {
        const error = new Error('Error while trying to fetch daily data');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const data = await response.json();

    return data;
}

export async function fetchMarketBreadthChart(startDate, endDate){

    const response = await checkTokenFetchServerRequest(`market_bredth/v2` + 
        (startDate != null ? `&start_date=${startDate}` : '') + 
        (endDate != null ? `&end_date=${endDate}` : ''));
    
        if (!response.ok)
    {
        const error = new Error('Error while trying to fetch market breadth chart');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const marketBreadthData = await response.json();

    return marketBreadthData;
}


export async function fetchOverviewData(ticker, date){

    const response = await checkTokenFetchServerRequest(`overview?asset=${ticker}&format=json/list` + 
        `&date=${date}`);
    
        if (!response.ok)
    {
        const error = new Error('Error while trying to fetch daily data');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const overviewData = await response.json();

    return overviewData;
}