import React, { useCallback, useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import HistoricVolumeChart from './HistoricVolumeChart';
import HistoricDeltaChart from './HistoricDeltaChart';

import { useQuery } from "@tanstack/react-query";
import { getUserMeta, setUserMeta } from "../../util/http";

import _ from 'lodash';

import Loading from "../Loading";
import Error from "../Error";
import { SAVE_KEY_HISTORICAL_DATE_INTERVAL, SAVE_KEY_HISTORICAL_METRICS } from '../../util/Constants';
import { HISTORIC_DELTA_CHART_SETTINGS_CONFIG, HISTORIC_DELTA_METRICS, HISTORIC_DELTA_TYPE, HISTORIC_MAIN_CHART_SETTINGS_CONFIG, HISTORIC_VOLUME_CHART_SETTINGS_CONFIG, HISTORIC_VOLUME_METRICS, HISTORIC_VOLUME_TYPE } from './HistoricSettingsConfig';
import NoTicker from '../ticker/NoTicker';
import { useDateInterval } from '../SynchedChartUtils';
import TVChartContainer from '../TVChartContainer';
import HistoricMainChart from './HistoricMainChart';

const defaultMetrics = {
    main: {
        metrics: ['rank_v1'],
        type: 'candlestick'
    },
    volume: {
        type: HISTORIC_VOLUME_TYPE.C_VOL_P_VOL,
    },
    delta: {
        type: HISTORIC_DELTA_TYPE.C_DELTA_P_DELTA,
    },
};

const chartInstances = [];

export default function HistoricTab({ selectedTicker }) {

    const [metrics, setMetrics] = useState(null/*defaultMetrics*/);
    const [isFullscreen, setFullscreen] = useState([false, false, false]);

    const { data: historicalDateInterval, isPending: isHistoricalDatePending, isFetching: isHistoricalDateFetching, isError: isHistoricalDateError, error: historicalDateError } = useQuery({
        //queryKey: [SAVE_KEY_HISTORICAL_DATE_INTERVAL, selectedTicker, ...isFullscreen],
        queryKey: ['user_meta', selectedTicker, ...isFullscreen],
        queryFn: () => getUserMeta(),
        select: (data) => data ? data[SAVE_KEY_HISTORICAL_DATE_INTERVAL]:null,
        enabled: !!selectedTicker,
        //initialData: defaultDateInterval,
    });

    const { data: historicalMetrics, isPending: isHistoricalMetricsPending, isFetching: isHistoricalMetricsFetching, isError: isHistoricalMetricsError, error: historicalMetricsError } = useQuery({
        //queryKey: [SAVE_KEY_HISTORICAL_METRICS, selectedTicker],
        queryKey: ['user_meta', selectedTicker, ...isFullscreen],
        queryFn: () => getUserMeta(),
        select: (data) => data ? data[SAVE_KEY_HISTORICAL_METRICS] : null,
        enabled: !!selectedTicker,
        //initialData: defaultMetrics,
    });

    //console.log(historicalDateInterval);    

    //console.log('historicalDateInterval: ', historicalDateInterval);
    const {startDate, endDate} = useDateInterval(historicalDateInterval);
    //console.log('endDate: ', endDate);

    useEffect(() => {
        if (!isHistoricalMetricsError && !isHistoricalMetricsPending && !isHistoricalMetricsFetching){
            if (process.env.NODE_ENV === 'development' || !!!historicalMetrics)
            {
                setMetrics(defaultMetrics);
            }
            else    
                setMetrics(historicalMetrics);
        }
    }, [historicalMetrics, isHistoricalMetricsError, isHistoricalMetricsFetching, isHistoricalMetricsPending, selectedTicker]);

    //console.log(historicData?.volumeData);
    const handleMetricsChanged = useCallback(({ selectedCheckboxes: indicators, selectedRadio: type }, chart) => {
        const newMetric = { metrics: indicators, type };
        if (!_.isEqual(metrics[chart], newMetric)) {
            setMetrics((prevMetrics) => {
                const nextMetrics = { ...prevMetrics, [chart]: newMetric };
                setUserMeta(SAVE_KEY_HISTORICAL_METRICS, nextMetrics);
                return nextMetrics;
            });
        }

    }, [metrics]);

    const saveHistoricInterval = useCallback((dateInterval) => { 
        setUserMeta(SAVE_KEY_HISTORICAL_DATE_INTERVAL, dateInterval);
    },[]);

    // const chart1 = useRef();
    // const chart2 = useRef();
    // const chart3 = useRef();
    // const chartRefs = useMemo(() => [chart1, chart2, chart3], [chart1, chart2, chart3]);

    const syncCursorMove = useCallback((sourceChart, dataPoint) => {
        if (dataPoint) {
            //console.log('dataPoint: ', dataPoint);
            chartInstances.forEach((chart) => {
                if (chart !== sourceChart && chart.series) {
                    //console.log('chart: ', chart);
                    chart.setCrosshairPosition(dataPoint.value, dataPoint.time, chart.series[0]);
                }
            });
        } else {
            chartInstances.forEach((chart) => chart.clearCrosshairPosition());
        }
    }, []);

    const onChartCreated = useCallback((chartRef) => {
        const chartInstance = chartRef.current?.chartInstance;
        if (chartInstance){
            chartInstances.push(chartInstance);
            if (chartInstances.length === 2){
                chartInstances.forEach((chartInstance) => {
                    chartInstance.subscribeCrosshairMove((param) => {
                        if (!param || !chartInstance.series[0])
                            return;
                        const dataPoint = param.seriesData.get(chartInstance.series[0]);
                        syncCursorMove(chartInstance, dataPoint);
                    });    
                });
            }
        }
    }, [syncCursorMove]);

    if (!selectedTicker)
        return <NoTicker />;

    if (isHistoricalDatePending || isHistoricalDateFetching || isHistoricalMetricsFetching || isHistoricalMetricsPending || !metrics)
        return <Loading />;

    if (isHistoricalDateError)
        return <Error error={historicalDateError} />;

    if (isHistoricalMetricsError)
        return <Error error={historicalMetricsError} />;

    return (
        <Grid container spacing={1} sx={{ height: '100%', '& > *': { marginTop: '0px !important', marginBottom: '0px !important' } }}>
            <Grid item xs={12} sx={{ height: '60%' }}>
                <TVChartContainer
                    ChartComponent={HistoricMainChart}
                    //ref={chartRefs[0]}
                    ticker={selectedTicker}
                    settingsConfig={HISTORIC_MAIN_CHART_SETTINGS_CONFIG}
                    metrics={metrics.main.metrics}
                    type={metrics.main.type}
                    startDate={startDate}
                    endDate={endDate}
                    fullscreen={isFullscreen[0]}
                    handleFullscreenChart={(value) => setFullscreen([value, false, false])}
                    handleMetricsChanged={(selectedMetrics) => handleMetricsChanged(selectedMetrics, 'main')}
                    handleSaveInterval={saveHistoricInterval}
                    onChartCreated={onChartCreated}
                //style={{height: '100%'}}//670 3.7
                />
            </Grid>
            <Grid item xs={12} sx={{ height: '20%' }}>
                <TVChartContainer
                    ChartComponent={HistoricVolumeChart}
                    //ref={chartRefs[1]}
                    ticker={selectedTicker}
                    settingsConfig={HISTORIC_VOLUME_CHART_SETTINGS_CONFIG}
                    metrics={HISTORIC_VOLUME_METRICS[metrics.volume.type]}
                    type={metrics.volume.type}
                    startDate={startDate}
                    endDate={endDate}
                    fullscreen={isFullscreen[1]}
                    handleFullscreenChart={(value) => setFullscreen([false, value, false])}
                    handleMetricsChanged={(selectedMetrics) => handleMetricsChanged(selectedMetrics, 'volume')}
                    handleSaveInterval={saveHistoricInterval}
                    onChartCreated={onChartCreated}
                //style={{height: '10%'}}//180
                />
            </Grid>
            <Grid item xs={12} sx={{ height: '20%' }}>
                <TVChartContainer
                    ChartComponent={HistoricDeltaChart}
                    //ref={chartRefs[2]}
                    ticker={selectedTicker}
                    settingsConfig={HISTORIC_DELTA_CHART_SETTINGS_CONFIG}
                    metrics={HISTORIC_DELTA_METRICS[metrics.delta.type]}
                    type={metrics.delta.type}
                    startDate={startDate}
                    endDate={endDate}
                    fullscreen={isFullscreen[2]}
                    handleFullscreenChart={(value) => setFullscreen([false, false, value])}
                    handleMetricsChanged={(selectedMetrics) => handleMetricsChanged(selectedMetrics, 'delta')}
                    handleSaveInterval={saveHistoricInterval}
                    onChartCreated={onChartCreated}
                //style={{height: '10%'}}
                />
            </Grid>
        </Grid>
    );
}