import { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import dayjs from 'dayjs';
import { createDistinctColorsHex } from '../../util/Util' 

const usePriceChart = ({ chartData, ticker, type = 'line', startDate, endDate, fullscreen, onChartCreated }) => {
    const chartContainerRef = useRef(null);
    const [refReset, setRefReset] = useState(false);

    useEffect(() => {
        if (!chartData || !chartContainerRef.current) return;

        if (refReset) setRefReset(false);

        const chart = createChart(chartContainerRef.current, {
            layout: {
                background: { color: '#000000' },
                textColor: '#ffffff',
            },
            grid: {
                vertLines: { visible: false },
                horzLines: { visible: false },
            },
            leftPriceScale: { visible: true },
            rightPriceScale: { visible: true },
            watermark: { visible: false },
        });

        const colors = createDistinctColorsHex(Object.keys(chartData).filter((key) => key !== 'Date').length);

        // Add main price series
        const priceOptions = {
            priceScaleId: 'right',
            color: colors[0],
            title: ticker,
            lineWidth: 1,
        };

        const priceSeries = type === 'line' ? chart.addLineSeries(priceOptions) : chart.addCandlestickSeries(priceOptions);

        priceSeries.setData(
            chartData.Date.map((date, index) => ({
                time: dayjs(date).format('YYYY-MM-DD'),
                ...(type === 'line' && { value: chartData['Price'][index] }),
                ...(type === 'candlestick' && {
                    open: chartData['Price'][index][0],
                    close: chartData['Price'][index][1],
                    low: chartData['Price'][index][2],
                    high: chartData['Price'][index][3],
                }),
            }))
        );
        chart.series = [];
        chart.series.push(priceSeries);

        // Add additional metrics as separate series
        Object.keys(chartData)
            .filter((key) => key !== 'Price' && key !== 'Date')
            .forEach((key, keyIndex) => {
                const metricSeries = chart.addLineSeries({
                    priceScaleId: 'left',
                    color: colors[keyIndex],
                    title: key,
                    lineWidth: 1,
                });
                chart.series.push(metricSeries);
                metricSeries.setData(
                    chartData.Date.map((date, index) => ({
                        time: dayjs(date).format('YYYY-MM-DD'),
                        value: chartData[key][index],
                    }))
                );
            });

        chart.timeScale().fitContent();

        // chart.timeScale().setVisibleRange({
        //     from: new Date(startDate).getTime() / 1000,
        //     to: new Date(endDate).getTime() / 1000,
        // });

        chartContainerRef.current.chartInstance = chart;

        if (onChartCreated)
            onChartCreated(chartContainerRef);


        return () => {
            chart.remove();
        };
    }, [chartData, ticker, type, startDate, endDate, refReset, onChartCreated]);

    useEffect(() => {
        if (fullscreen && !chartContainerRef.current) {
            const interval = setInterval(() => {
                if (chartContainerRef.current) {
                    clearInterval(interval);
                    setRefReset(true);
                }
            }, 50);

            return () => clearInterval(interval);
        }
    }, [fullscreen]);    

    return chartContainerRef;
};

export default usePriceChart;
