import { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import dayjs from 'dayjs';
import { createDistinctColorsHex } from '../../util/Util' 

const useHistogramChart = ({ chartData, ticker, startDate, endDate, fullscreen, onChartCreated }) => {
    const chartContainerRef = useRef(null);
    const [refReset, setRefReset] = useState(false);

    useEffect(() => {
        if (!chartData || !chartContainerRef.current) return;

        if (refReset) setRefReset(false);

        const chart = createChart(chartContainerRef.current, {
            layout: {
                background: { color: '#000000' },
                textColor: '#ffffff',
            },
            grid: {
                vertLines: { visible: false },
                horzLines: { visible: false },
            },
            rightPriceScale: { visible: true },
            watermark: { visible: false },
        });

        const colors = createDistinctColorsHex(Object.keys(chartData).filter((key) => key !== 'Date').length);

        chart.series = [];
        Object.keys(chartData)
            .filter((key) => key !== 'Date')
            .forEach((key, keyIndex) => {

                const seriesOption = {
                    priceScaleId: 'right',
                    color: colors[keyIndex],
                    title: key,
                };
                const metricSeries = chart.addHistogramSeries(seriesOption);                

                metricSeries.setData(
                    chartData.Date.map((date, index) => ({
                        time: dayjs(date).format('YYYY-MM-DD'),
                        value: chartData[key][index],
                    }))
                );

                chart.series.push(metricSeries);
            });

        chart.timeScale().fitContent();

        chartContainerRef.current.chartInstance = chart;

        if (onChartCreated)
            onChartCreated(chartContainerRef);

        return () => {
            chart.remove();
        };
    }, [chartData, ticker, startDate, endDate, refReset, onChartCreated]);

    useEffect(() => {
        if (fullscreen && !chartContainerRef.current) {
            const interval = setInterval(() => {
                if (chartContainerRef.current) {
                    clearInterval(interval);
                    setRefReset(true);
                }
            }, 50);

            return () => clearInterval(interval);
        }
    }, [fullscreen]);    

    return chartContainerRef;
};

export default useHistogramChart;
