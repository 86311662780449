Object.defineProperty(exports, "__esModule", { value: true });
exports.positionsLine = positionsLine;
exports.positionsBox = positionsBox;
function centreOffset(lineBitmapWidth) {
    return Math.floor(lineBitmapWidth * 0.5);
}
/**
 * Calculates the bitmap position for an item with a desired length (height or width), and centred according to
 * an position coordinate defined in media sizing.
 * @param positionMedia - position coordinate for the bar (in media coordinates)
 * @param pixelRatio - pixel ratio. Either horizontal for x positions, or vertical for y positions
 * @param desiredWidthMedia - desired width (in media coordinates)
 * @returns Position of of the start point and length dimension.
 */
function positionsLine(positionMedia, pixelRatio, desiredWidthMedia, widthIsBitmap) {
    if (desiredWidthMedia === void 0) { desiredWidthMedia = 1; }
    var scaledPosition = Math.round(pixelRatio * positionMedia);
    var lineBitmapWidth = widthIsBitmap
        ? desiredWidthMedia
        : Math.round(desiredWidthMedia * pixelRatio);
    var offset = centreOffset(lineBitmapWidth);
    var position = scaledPosition - offset;
    return { position: position, length: lineBitmapWidth };
}
/**
 * Determines the bitmap position and length for a dimension of a shape to be drawn.
 * @param position1Media - media coordinate for the first point
 * @param position2Media - media coordinate for the second point
 * @param pixelRatio - pixel ratio for the corresponding axis (vertical or horizontal)
 * @returns Position of of the start point and length dimension.
 */
function positionsBox(position1Media, position2Media, pixelRatio) {
    var scaledPosition1 = Math.round(pixelRatio * position1Media);
    var scaledPosition2 = Math.round(pixelRatio * position2Media);
    return {
        position: Math.min(scaledPosition1, scaledPosition2),
        length: Math.abs(scaledPosition2 - scaledPosition1) + 1,
    };
}
