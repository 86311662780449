Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateColumnPositions = calculateColumnPositions;
exports.calculateColumnPositionsInPlace = calculateColumnPositionsInPlace;
var alignToMinimalWidthLimit = 4;
var showSpacingMinimalBarWidth = 1;
/**
 * Spacing gap between columns.
 * @param barSpacingMedia - spacing between bars (media coordinate)
 * @param horizontalPixelRatio - horizontal pixel ratio
 * @returns Spacing gap between columns (in Bitmap coordinates)
 */
function columnSpacing(barSpacingMedia, horizontalPixelRatio) {
    return Math.ceil(barSpacingMedia * horizontalPixelRatio) <=
        showSpacingMinimalBarWidth
        ? 0
        : Math.max(1, Math.floor(horizontalPixelRatio));
}
/**
 * Desired width for columns. This may not be the final width because
 * it may be adjusted later to ensure all columns on screen have a
 * consistent width and gap.
 * @param barSpacingMedia - spacing between bars (media coordinate)
 * @param horizontalPixelRatio - horizontal pixel ratio
 * @param spacing - Spacing gap between columns (in Bitmap coordinates). (optional, provide if you have already calculated it)
 * @returns Desired width for column bars (in Bitmap coordinates)
 */
function desiredColumnWidth(barSpacingMedia, horizontalPixelRatio, spacing) {
    return (Math.round(barSpacingMedia * horizontalPixelRatio) -
        (spacing !== null && spacing !== void 0 ? spacing : columnSpacing(barSpacingMedia, horizontalPixelRatio)));
}
/**
 * Calculated values which are common to all the columns on the screen, and
 * are required to calculate the individual positions.
 * @param barSpacingMedia - spacing between bars (media coordinate)
 * @param horizontalPixelRatio - horizontal pixel ratio
 * @returns calculated values for subsequent column calculations
 */
function columnCommon(barSpacingMedia, horizontalPixelRatio) {
    var spacing = columnSpacing(barSpacingMedia, horizontalPixelRatio);
    var columnWidthBitmap = desiredColumnWidth(barSpacingMedia, horizontalPixelRatio, spacing);
    var shiftLeft = columnWidthBitmap % 2 === 0;
    var columnHalfWidthBitmap = (columnWidthBitmap - (shiftLeft ? 0 : 1)) / 2;
    return {
        spacing: spacing,
        shiftLeft: shiftLeft,
        columnHalfWidthBitmap: columnHalfWidthBitmap,
        horizontalPixelRatio: horizontalPixelRatio,
    };
}
/**
 * Calculate the position for a column. These values can be later adjusted
 * by a second pass which corrects widths, and shifts columns.
 * @param xMedia - column x position (center) in media coordinates
 * @param columnData - precalculated common values (returned by `columnCommon`)
 * @param previousPosition - result from this function for the previous bar.
 * @returns initial column position
 */
function calculateColumnPosition(xMedia, columnData, previousPosition) {
    var xBitmapUnRounded = xMedia * columnData.horizontalPixelRatio;
    var xBitmap = Math.round(xBitmapUnRounded);
    var xPositions = {
        left: xBitmap - columnData.columnHalfWidthBitmap,
        right: xBitmap +
            columnData.columnHalfWidthBitmap -
            (columnData.shiftLeft ? 1 : 0),
        shiftLeft: xBitmap > xBitmapUnRounded,
    };
    var expectedAlignmentShift = columnData.spacing + 1;
    if (previousPosition) {
        if (xPositions.left - previousPosition.right !== expectedAlignmentShift) {
            // need to adjust alignment
            if (previousPosition.shiftLeft) {
                previousPosition.right = xPositions.left - expectedAlignmentShift;
            }
            else {
                xPositions.left = previousPosition.right + expectedAlignmentShift;
            }
        }
    }
    return xPositions;
}
function fixPositionsAndReturnSmallestWidth(positions, initialMinWidth) {
    return positions.reduce(function (smallest, position) {
        if (position.right < position.left) {
            position.right = position.left;
        }
        var width = position.right - position.left + 1;
        return Math.min(smallest, width);
    }, initialMinWidth);
}
function fixAlignmentForNarrowColumns(positions, minColumnWidth) {
    return positions.map(function (position) {
        var width = position.right - position.left + 1;
        if (width <= minColumnWidth)
            return position;
        if (position.shiftLeft) {
            position.right -= 1;
        }
        else {
            position.left += 1;
        }
        return position;
    });
}
/**
 * Calculates the column positions and widths for the x positions.
 * This function creates a new array. You may get faster performance using the
 * `calculateColumnPositionsInPlace` function instead
 * @param xMediaPositions - x positions for the bars in media coordinates
 * @param barSpacingMedia - spacing between bars in media coordinates
 * @param horizontalPixelRatio - horizontal pixel ratio
 * @returns Positions for the columns
 */
function calculateColumnPositions(xMediaPositions, barSpacingMedia, horizontalPixelRatio) {
    var common = columnCommon(barSpacingMedia, horizontalPixelRatio);
    var positions = new Array(xMediaPositions.length);
    var previous = undefined;
    for (var i = 0; i < xMediaPositions.length; i++) {
        positions[i] = calculateColumnPosition(xMediaPositions[i], common, previous);
        previous = positions[i];
    }
    var initialMinWidth = Math.ceil(barSpacingMedia * horizontalPixelRatio);
    var minColumnWidth = fixPositionsAndReturnSmallestWidth(positions, initialMinWidth);
    if (common.spacing > 0 && minColumnWidth < alignToMinimalWidthLimit) {
        return fixAlignmentForNarrowColumns(positions, minColumnWidth);
    }
    return positions;
}
/**
 * Calculates the column positions and widths for bars using the existing the
 * array of items.
 * @param items - bar items which include an `x` property, and will be mutated to contain a column property
 * @param barSpacingMedia - bar spacing in media coordinates
 * @param horizontalPixelRatio - horizontal pixel ratio
 * @param startIndex - start index for visible bars within the items array
 * @param endIndex - end index for visible bars within the items array
 */
function calculateColumnPositionsInPlace(items, barSpacingMedia, horizontalPixelRatio, startIndex, endIndex) {
    var common = columnCommon(barSpacingMedia, horizontalPixelRatio);
    var previous = undefined;
    for (var i = startIndex; i < Math.min(endIndex, items.length); i++) {
        items[i].column = calculateColumnPosition(items[i].x, common, previous);
        previous = items[i].column;
    }
    var minColumnWidth = items.reduce(function (smallest, item, index) {
        if (!item.column || index < startIndex || index > endIndex)
            return smallest;
        if (item.column.right < item.column.left) {
            item.column.right = item.column.left;
        }
        var width = item.column.right - item.column.left + 1;
        return Math.min(smallest, width);
    }, Math.ceil(barSpacingMedia * horizontalPixelRatio));
    if (common.spacing > 0 && minColumnWidth < alignToMinimalWidthLimit) {
        items.forEach(function (item, index) {
            if (!item.column || index < startIndex || index > endIndex)
                return;
            var width = item.column.right - item.column.left + 1;
            if (width <= minColumnWidth)
                return item;
            if (item.column.shiftLeft) {
                item.column.right -= 1;
            }
            else {
                item.column.left += 1;
            }
            return item.column;
        });
    }
}
