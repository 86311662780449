//import { useQuery } from "@tanstack/react-query";
//import { fetchHistoricChart } from "../util/http";

//import Loading from "./Loading";
//import Error from "./Error";
import { forwardRef, memo, useEffect, useMemo, useRef } from "react";

import { Box } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchHistoricChart } from "../../util/http";
import Loading from "../Loading";
import Error from "../Error";
import HistoricMeta from "./HistoricMeta";
import FullscreenChart from "../FullscreenChart";
import usePriceChart from "../TVCharts/UsePriceChart";

// const arePropsEqual = (prevProps, nextProps) => {
//     console.log('here!');
//     let equal = true;
//     for (let key in prevProps) {
//       if (prevProps[key] !== nextProps[key]) {
//         equal = false;
//         console.log(`Prop '${key}' changed from '${prevProps[key]}' to '${nextProps[key]}'`);
//       }
//     }
//     return equal;
// }

let prevQueryKey = null;
async function fetchHistoricData(queryClient, ticker, metrics, fw_deltas, type){
    const fw_delta_metric = 'pct_l_delta_minus_pct_h_delta';
    const cachedQuery = prevQueryKey ? queryClient.getQueryData(prevQueryKey)?.data : null;
    let cachedData = {};            
    //console.log(cachedQuery);    

    if (cachedQuery && prevQueryKey.find((key) => key === ticker) !== undefined){
        //console.log('cached!');
        cachedData = Object.fromEntries(Object.entries(cachedQuery).filter(([key, value]) => metrics.find((metric) => HistoricMeta.getInstance().getMetricDescription(metric) === key) !== undefined));
        metrics = metrics.filter((metric) => Object.keys(cachedData).find((key) => key === HistoricMeta.getInstance().getMetricDescription(metric)) === undefined);        
        Object.assign(cachedData, Object.fromEntries(Object.entries(cachedQuery).filter(([key, value]) => fw_deltas.find((fw_delta) => HistoricMeta.getInstance().getDeltaDescription(fw_delta, fw_delta_metric) === key) !== undefined)));
        fw_deltas = fw_deltas.filter((fw_delta) => Object.keys(cachedData).find((key) => key === HistoricMeta.getInstance().getDeltaDescription(fw_delta, fw_delta_metric)) === undefined);
        if (type === prevQueryKey[prevQueryKey.length - 1])
            cachedData['Price'] = cachedQuery['Price'];
        cachedData['Date'] = cachedQuery['Date'];
        //console.log('deltas: ', fw_deltas);
        //console.log('here: ', cachedData);
    }

    prevQueryKey = ['historicalChart', 'main', ticker, [...metrics], fw_deltas, type];        
    
    metrics = metrics.filter((metric) => !metric.startsWith('fw'));
    let extra_params = '';
    if (fw_deltas.length > 0){ 
        extra_params += 'forward_delta_periods=' + fw_deltas.join();
        extra_params += `&forward_delta_metrics=${fw_delta_metric}`;
        extra_params += '&sma=5';
    }
    if (!cachedData?.Price){
        extra_params += type === 'candlestick' ? '&price=ohlc' : '&price=close';
    }

    if (cachedData?.Date){
        extra_params += '&exclude_date=true';
    }
    
    const data = await fetchHistoricChart(ticker, metrics, extra_params, null, null);
    //console.log('data: ', data);
    if (cachedData){        
        Object.assign(data.data, cachedData);
        //console.log(data.data);
    }

    return data;
}

const HistoricMainChart = memo(forwardRef(({ ticker, metrics, type, sharedOption, startDate, endDate, fullscreen, handleCloseFullscreen, handleFullsceenSettings, onChartCreated, ...props }, ref) => {    
   //const dataProviderRef = useRef(null);
    //console.log('startDate: ', startDate);
    //console.log('endDate: ', endDate);
    //console.log(metrics);

    //type = 'line';
    const queryClient = useQueryClient();    
    const fw_deltas = metrics.filter((metric) => metric.startsWith('fw')).map((metric)=>metric.substring(2));
    metrics = metrics.filter((metric) => !metric.startsWith('fw'));

    const queryKey = useMemo(() => ['historicalChart', 'main', ticker, [...metrics], [...fw_deltas], type], [ticker, metrics, fw_deltas, type]);
    const prevQueryKey = useRef();
    
    useEffect(() => {
        prevQueryKey.current = queryKey;
    }, [queryKey]);

    const { data, isPending, isFetching, isError, error } = useQuery({
        queryKey,
        queryFn: () => fetchHistoricData(queryClient, ticker, metrics, fw_deltas, type),
        refetchOnMount: false,
    });

    
    let chartData = data?.data; 

    const chartContainerRef = usePriceChart({
        chartData,
        ticker,
        type,
        startDate,
        endDate,
        fullscreen,
        onChartCreated
    });

    if ((isPending || isFetching) && !chartData)
        return <Loading />;

    if (isError)
        return <Error error={error} />;

    
    if (fullscreen){        
        return (
            <FullscreenChart
                ref={chartContainerRef} 
                open={fullscreen}
                handleClose={handleCloseFullscreen}
                handleSettings={handleFullsceenSettings}
            />
        );
    }
    
    return (
        <Box 
            ref={chartContainerRef} 
            sx={{ 
                    height: '100%',
                    '& #tv-attr-logo': {
                        display: 'none !important',
                    },
                }}  
        />
    );
}));


export default HistoricMainChart;