
Object.defineProperty(exports, "__esModule", { value: true });
exports.StackedBarsSeries = void 0;
var options_1 = require("./options");
var renderer_1 = require("./renderer");
var StackedBarsSeries = /** @class */ (function () {
    function StackedBarsSeries() {
        this._renderer = new renderer_1.StackedBarsSeriesRenderer();
    }
    StackedBarsSeries.prototype.priceValueBuilder = function (plotRow) {
        // return [
        //     0,
        //     plotRow.values.reduce(function (previousValue, currentValue) { return previousValue + currentValue; }, 0),
        // ];
        //console.log('values: ', plotRow.values);
        return plotRow.values;
    };
    StackedBarsSeries.prototype.isWhitespace = function (data) {
        var _a;
        return !Boolean((_a = data.values) === null || _a === void 0 ? void 0 : _a.length);
    };
    StackedBarsSeries.prototype.renderer = function () {
        return this._renderer;
    };
    StackedBarsSeries.prototype.update = function (data, options) {
        this._renderer.update(data, options);
    };
    StackedBarsSeries.prototype.defaultOptions = function () {
        return options_1.defaultOptions;
    };
    return StackedBarsSeries;
}());
exports.StackedBarsSeries = StackedBarsSeries;
