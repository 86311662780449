import { forwardRef, memo } from "react";
import { Box, Dialog, DialogActions, DialogContent, IconButton } from "@mui/material";
import { FullscreenExit, Settings } from "@mui/icons-material";


const FullscreenChart = memo(forwardRef(({ open, handleClose, handleSettings }, ref) => {

    return (
        <Dialog 
            open={open} 
            onClose={handleClose} 
            fullScreen
            style={{zIndex:10000000}}
            >
            <DialogActions style={{ position: 'absolute', top: 0, right: 0, zIndex: 1000  }}>
                {handleSettings && 
                    <IconButton onClick={(event) => {event.preventDefault(); event.stopPropagation(); handleSettings();}}>
                        <Settings />
                    </IconButton >
                }
                <IconButton onClick={handleClose}>
                    <FullscreenExit />
                </IconButton >
            </DialogActions>
            <DialogContent>
                <Box 
                    ref={ref} 
                    sx={{ 
                            height: '100%',
                            '& #tv-attr-logo': 
                            {
                                display: 'none !important',
                            },
                        }}  
                />
            </DialogContent>
        </Dialog>
    );
}));

export default FullscreenChart;