//import { useQuery } from "@tanstack/react-query";
//import { fetchHistoricChart } from "../util/http";

//import Loading from "./Loading";
//import Error from "./Error";
import { forwardRef, memo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchHistoricChart } from "../../util/http";
import Loading from "../Loading";
import Error from "../Error";
import { Box } from "@mui/material";
import FullscreenChart from "../FullscreenChart";
import useStackedBarChart from "../TVCharts/UseStackedBarChart";
//import HistoricMeta from "./HistoricMeta";

let prevQueryKey = null;
async function fetchHistoricData(queryClient, ticker, metrics){
    const cachedQuery = prevQueryKey ? queryClient.getQueryData(prevQueryKey)?.data : null;
    let cachedData = {};            

    if (cachedQuery  && prevQueryKey.find((key) => key === ticker) !== undefined){
        cachedData['Date'] = cachedQuery['Date'];
    }

    let extra_params = '&price=none';

    if (cachedData.Date){
        extra_params += '&exclude_date=true';
    }

    prevQueryKey = ['historicalChart', 'volume', ticker, [...metrics]];

    const data = await fetchHistoricChart(ticker, metrics, extra_params, null, null);

    if (cachedData){
        Object.assign(data.data, cachedData);
    }

    return data;

}

const HistoricDeltaChart = memo(forwardRef(({ticker, metrics, type, sharedOption, startDate, endDate, fullscreen, handleCloseFullscreen, handleFullsceenSettings, onChartCreated, ...props}, ref) =>{

    const queryClient = useQueryClient();        
    const { data: { data: chartData } = { data: [] }, isPending, isFetching, isError, error } = useQuery({
        queryKey: ['historicalChart', 'volume', ticker, [...metrics]/*, type*//*, startDate, endDate*/],
        queryFn: () => fetchHistoricData(queryClient, ticker, metrics),   
        refetchOnMount: false,
    });


    const chartContainerRef = useStackedBarChart({
        chartData,
        ticker,        
        startDate,
        endDate,
        fullscreen,
        onChartCreated,
    });


    if (isPending || isFetching)
        return <Loading />;

    if (isError)
        return <Error error={error} />

    if (fullscreen){        
        return (
            <FullscreenChart
                ref={chartContainerRef} 
                open={fullscreen}
                handleClose={handleCloseFullscreen}
                handleSettings={handleFullsceenSettings}
            />
        );
    }

    return (
        <Box 
            ref={chartContainerRef} 
            sx={{ 
                    height: '100%',
                    '& #tv-attr-logo': {
                        display: 'none !important',
                    },
                }}  
        />
    );    
}));

export default HistoricDeltaChart;