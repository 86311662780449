//import { useQuery } from "@tanstack/react-query";
//import { fetchHistoricChart } from "../util/http";

//import Loading from "./Loading";
//import Error from "./Error";
import { forwardRef, memo, useEffect, useMemo, useRef } from "react";

import SynchedChart from "../SynchedChart";
import { Box } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchHistoricChart } from "../../util/http";
import Loading from "../Loading";
import Error from "../Error";
import HistoricMeta from "./HistoricMeta";
//import HistoricChartDataProvider from "./HistoricChartDataProvider";

// const arePropsEqual = (prevProps, nextProps) => {
//     console.log('here!');
//     let equal = true;
//     for (let key in prevProps) {
//       if (prevProps[key] !== nextProps[key]) {
//         equal = false;
//         console.log(`Prop '${key}' changed from '${prevProps[key]}' to '${nextProps[key]}'`);
//       }
//     }
//     return equal;
// }

let prevQueryKey = null;
async function fetchHistoricData(queryClient, ticker, metrics, fw_deltas, type){
    const fw_delta_metric = 'pct_l_delta_minus_pct_h_delta';
    const cachedQuery = prevQueryKey ? queryClient.getQueryData(prevQueryKey)?.data : null;
    let cachedData = {};            
    //console.log(cachedQuery);    

    if (cachedQuery && prevQueryKey.find((key) => key === ticker) !== undefined){
        //console.log('cached!');
        cachedData = Object.fromEntries(Object.entries(cachedQuery).filter(([key, value]) => metrics.find((metric) => HistoricMeta.getInstance().getMetricDescription(metric) === key) !== undefined));
        metrics = metrics.filter((metric) => Object.keys(cachedData).find((key) => key === HistoricMeta.getInstance().getMetricDescription(metric)) === undefined);        
        Object.assign(cachedData, Object.fromEntries(Object.entries(cachedQuery).filter(([key, value]) => fw_deltas.find((fw_delta) => HistoricMeta.getInstance().getDeltaDescription(fw_delta, fw_delta_metric) === key) !== undefined)));
        fw_deltas = fw_deltas.filter((fw_delta) => Object.keys(cachedData).find((key) => key === HistoricMeta.getInstance().getDeltaDescription(fw_delta, fw_delta_metric)) === undefined);
        if (type === prevQueryKey[prevQueryKey.length - 1])
            cachedData['Price'] = cachedQuery['Price'];
        cachedData['Date'] = cachedQuery['Date'];
        //console.log('deltas: ', fw_deltas);
        //console.log('here: ', cachedData);
    }

    prevQueryKey = ['historicalChart', 'main', ticker, [...metrics], fw_deltas, type];        
    
    metrics = metrics.filter((metric) => !metric.startsWith('fw'));
    let extra_params = '';
    if (fw_deltas.length > 0){ 
        extra_params += 'forward_delta_periods=' + fw_deltas.join();
        extra_params += `&forward_delta_metrics=${fw_delta_metric}`;
        extra_params += '&sma=5';
    }
    if (!cachedData?.Price){
        extra_params += type === 'candlestick' ? '&price=ohlc' : '&price=close';
    }

    if (cachedData?.Date){
        extra_params += '&exclude_date=true';
    }
    
    const data = await fetchHistoricChart(ticker, metrics, extra_params, null, null);
    //console.log('data: ', data);
    if (cachedData){        
        Object.assign(data.data, cachedData);
        //console.log(data.data);
    }

    return data;
}

const ApacheHistoricMainChart = memo(forwardRef(({ ticker, metrics, type, sharedOption, startDate, endDate, ...props }, ref) => {    
   //const dataProviderRef = useRef(null);
    //console.log('startDate: ', startDate);
    //console.log('endDate: ', endDate);
    //console.log(metrics);

    const queryClient = useQueryClient();    
    const fw_deltas = metrics.filter((metric) => metric.startsWith('fw')).map((metric)=>metric.substring(2));
    metrics = metrics.filter((metric) => !metric.startsWith('fw'));

    const queryKey = useMemo(() => ['historicalChart', 'main', ticker, [...metrics], [...fw_deltas], type], [ticker, metrics, fw_deltas, type]);
    const prevQueryKey = useRef();
    
    useEffect(() => {
        prevQueryKey.current = queryKey;
    }, [queryKey]);

    const { data, isPending, isFetching, isError, error } = useQuery({
        queryKey,
        queryFn: () => fetchHistoricData(queryClient, ticker, metrics, fw_deltas, type),
        refetchOnMount: false,
        //staleTime: 60 * 60 * 1000,
    });

    
    let chartData = data?.data; 
    //console.log('metrics: ', metrics);
    //console.log('chartData != null', chartData !== null);
    if (!chartData && prevQueryKey.current && prevQueryKey.current !== queryKey && prevQueryKey.current.find((key) => key === ticker) !== undefined        
        && prevQueryKey.current.length + 2 >= queryKey.length){
        console.log('cached!');
        //console.log('chached! ', prevQueryKey.current.find((key) => key === ticker);
        // console.log(prevQueryKey.current.length);
        // console.log(queryKey.length);
        chartData = queryClient.getQueryData(prevQueryKey.current)?.data;
    }    

    //console.log('query: ', ['historicalChart', 'main', ticker, [...metrics], fw_deltas, type]);
    //console.log('pending: ', isPending);
    //console.log('fetching: ', isFetching);

    if ((isPending || isFetching) && !chartData)
        return <Loading />;

    if (isError)
        return <Error error={error} />;



    //console.log(' main data: ', chartData);

    //console.log(chartData['Date']);
    //console.log('interval: ', Math.round(chartData['Date'].length / 80) - 1);
    const startValue = chartData['Date'].findIndex((date) => date === startDate) !== -1 ? chartData['Date'].findIndex((date) => date === startDate) : chartData['Date'].length - 500;
    const endValue = chartData['Date'].findIndex((date) => date === endDate) !== -1 ? chartData['Date'].findIndex((date) => date === endDate) : chartData['Date'].length - 1;    
    const numDataPoints = endValue - startValue;
    //console.log('Num data points: ', numDataPoints);
    //console.log('main date: ', startDate);
    //console.log('main date index: ', chartData['Date'][startValue]);
    const option = {
        ...sharedOption,
        grid: {
            left: '10px', //'4%',
            right: '10px', //'4%',
            bottom: '10px', //'4%',
            top: '50px', //'12%',
            containLabel: true,
        },
        title: {
            text: ticker,
            left: 'center',
            top: '2%'
        },
        legend: {
            data: Object.keys(chartData).filter(key => key !== 'Date'),
            icon: 'circle',
            top: '6%',
            left: 'center',
        },
        xAxis:
        {
            id: 'date',
            type: 'category',
            data: chartData['Date'],
            boundaryGap: false,
            axisPointer: {
                type: "shadow",
                show: true,
                label: { show: true },
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                maxLabels: 30,
                interval: Math.round(numDataPoints / 30) - 1,
                rotate: 30,
                fontSize: 8,
            }

        },
        yAxis: [
            {
                position: 'right',
                type: 'value',
                data: chartData['Price'],
                splitLine: {
                    show: false,
                },
                axisPointer: {
                    type: "line",
                    show: true,
                    triggerTooltip: false,
                },
                scale: true,
                axisLabel: {
                    fontSize: 8,
                },
            },
            ...Object.keys(chartData).filter((key) => key !== 'Price' && key !== 'Date').map((key) => ({
                position: 'left',
                type: 'value',
                data: chartData[key],
                splitLine: {
                    show: false,
                },
                scale: true,
                axisLabel: {
                    fontSize: 8,
                },
            })),
        ],
        series: [
            ...Object.keys(chartData).filter((key) => key !== 'Price' && key !== 'Date').map((key) => ({
                name: key,
                data: chartData[key],
                type: 'line',
                yAxisIndex: 1,
                symbol: 'none',
                smooth: true,
                //showAllSymbol: false,
            })),
            {
                name: 'Price',
                data: chartData['Price'],
                type: type,
                xAxisIndex: 0,
                yAxisIndex: 0,  
                large: true,
                symbol: 'none',
                smooth: true,
                itemStyle: {"color0": "#ef232a", "color": "#14b143", "borderColor0": "#ef232a", "borderColor": "#14b143"}
                //yAxisIndex: 0,
                //showAllSymbol: false,
            },
        ],
        dataZoom: [
            {
                ...sharedOption.dataZoom[0],
                startValue, 
                endValue, 
            }
        ]
    };
    //console.log(option.yAxis);
    //console.log('series: ', option.series);

    //console.log(option.dataZoom);

    return (
        <Box sx={{ height: '100%' }}>
            <SynchedChart
                {...props}
                option={option}
                ref={ref}
            //dispatchFn={dispatchFn}
            //style={process.env.NODE_ENV !== 'development' ? { height: '450px' } : {}}
            //hideTooltipContent={hideTooltipContent}                
            />
        </Box>
    );
})/*, arePropsEqual*/);


export default ApacheHistoricMainChart;