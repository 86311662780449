import dayjs from "dayjs";

export function isMarketClosed(dateJS){
    let date = dayjs(dateJS);

    if (date.day() === 0 || date.day() === 6) // weekend
        return true;

    if (date.month() === 0 && date.week() === 0 && date.day() === 1) // new year day
        return true;

    if (date.month() === 5 && date.day() === 19) // Juneteenth National Independence Day
        return true;

    if (date.month() === 11 && date.day() === 25) // Christmas!!!
        return true;

    if (date.isSame(dayjs(`${date.year()}-01-01`).startOf("month").day(8).add(2, "week"))) // Martin Luther King Jr. Day - Third Monday in January
        return true;

    if (date.isSame(dayjs(`${date.year()}-02-01`).startOf("month").day(8).add(2, "week"))) // Presidents' Day - Third Monday in February
        return true;

    if (date.isSame(dayjs(`${date.year()}-05-31`).day(1))) // Memorial Day Last Monday in May
        return true;

    if (date.isSame(dayjs(`${date.year()}-09-01`).day(1))) // Labor Day - First Monday in September
        return true;

    if (date.isSame(dayjs(`${date.year()}-11-31`).day(4))) // Thanksgiving - Fourth Thursday in November
        return true;

    return false;
};

export function convertJsDateToServer(date){
    return date.toISOString().slice(0, 10).replace(/-/g, "");
}

export function convertServerDateToJs(date)
{
    return date.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
}

export function createDistinctColors(numColors) 
{
    const colors = [];
    const goldenRatioConjugate = 0.618033988749895;
    let hue = 0.3333333333; //green

    for (let i = 0; i < numColors; i++) 
    {
        const color = `hsl(${Math.floor(hue * 360)}, 80%, 70%)`;
        colors.push(color);

        hue += goldenRatioConjugate;
        hue %= 1;
    }

    return colors;
}


function hslToHex(h, s, l) {
    s /= 100;
    l /= 100;

    const k = n => (n + h / 30) % 12;
    const a = s * Math.min(l, 1 - l);

    const f = n => Math.round(255 * (l - a * Math.max(-1, Math.min(k(n) - 3, 9 - k(n), 1))));

    const r = f(0);
    const g = f(8);
    const b = f(4);

    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}

export function createDistinctColorsHex(numColors) {
    const colors = [];
    const goldenRatioConjugate = 0.618033988749895;
    let hue = 0.3333333333; // green

    for (let i = 0; i < numColors; i++) {
        // Convert HSL to Hex
        colors.push(hslToHex(hue * 360, 80, 70));

        // Update hue using the golden ratio
        hue += goldenRatioConjugate;
        hue %= 1;
    }

    return colors;
}
