" ";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultOptions = void 0;
var lightweight_charts_1 = require("lightweight-charts");
exports.defaultOptions = __assign(__assign({}, lightweight_charts_1.customSeriesDefaultOptions), { colors: [
        '#2962FF',
        '#E1575A',
        '#F28E2C',
        'rgb(164, 89, 209)',
        'rgb(27, 156, 133)',
    ] });
